import { ReactNode } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";

import styles from "./DetailsText.module.scss";

type DetailsTextProps = {
  label: string;
  children: ReactNode;
  hint?: boolean;
};

const DetailsText = ({ children, label, hint = false }: DetailsTextProps) => (
  <div className={styles.detailsText}>
    <p className={styles.label}>
      <span>{label}</span>
      {hint && (
        <span className={styles.hint}>
          <a
            href="https://purefi-protocol.medium.com/local-trade-purefi-round-starting-on-nov-25-2021-1aa310cf6fe7"
            target="_blank"
            rel="noopener noreferrer"
          >
            <QuestionCircleOutlined style={{ color: "#983eff" }} />
          </a>
        </span>
      )}
    </p>

    <p className={styles.text}>{children}</p>
  </div>
);

export default DetailsText;
