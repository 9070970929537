import { v4 as uuidv4 } from "uuid";

import { RoundConfig } from "models";

import ProductionScanner from "helpers/ProductionScanner";

import purefiLogo from "assets/purefi-logo.svg";
import lttLogo from "assets/ltt-logo.png";

import { BSC_MAINNET_NETWORK } from "./networks";

const IS_PRODUCTION = ProductionScanner.check();

// array of PRODUCTION rounds
// they are always present among rounds regardless env
const PRODUCTION_ROUND_CONFIGS: RoundConfig[] = [
  {
    id: uuidv4(),
    contractAddress: "0x0274c78595B25eBBA4F9e20610422d04d8Dc8086",
    networkId: BSC_MAINNET_NETWORK.networkId,
    startDate: "2021-11-25T16:00:00Z",
    endDate: "2021-12-07T00:00:00Z",
    bscDepositTokenAddress: "0xe2a59d5e33c6540e18aaa46bf98917ac3158db0d",
    depositToken: {
      symbol: "UFI",
      icon: purefiLogo,
    },
    rewardToken: {
      symbol: "LTT",
      icon: lttLogo,
    },
  },
];

// array of NON-PRODUCTION rounds
// they won't appear in production if REACT_APP_IS_PRODUCTION === 'true'
// for details see how github action workflows are configred
const OTHER_ROUND_CONFIGS: RoundConfig[] = [];

const ROUNDS: RoundConfig[] = PRODUCTION_ROUND_CONFIGS;

if (!IS_PRODUCTION && OTHER_ROUND_CONFIGS.length > 0) {
  ROUNDS.push(...OTHER_ROUND_CONFIGS);
}

export default ROUNDS;
