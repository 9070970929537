import ProductionScanner from "helpers/ProductionScanner";

import {
  ETHEREUM_MAINNET_NETWORK,
  BSC_MAINNET_NETWORK,
  RINKEBY_NETWORK,
  BSC_TEST_NETWORK,
} from "./networks";

const IS_PRODUCTION = ProductionScanner.check();

const rpc = {
  [ETHEREUM_MAINNET_NETWORK.networkId]: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`,
  [BSC_MAINNET_NETWORK.networkId]: `https://bsc-dataseed1.binance.org`,
};

if (!IS_PRODUCTION) {
  rpc[
    RINKEBY_NETWORK.networkId
  ] = `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`;
  rpc[
    BSC_TEST_NETWORK.networkId
  ] = `https://data-seed-prebsc-1-s1.binance.org:8545`;
}

const WALLET_CONNECT_CONFIG = {
  infuraId: process.env.REACT_APP_INFURA_API_KEY,
  rpc,
};

export default WALLET_CONNECT_CONFIG;
