import { useState } from "react";

import { Card, Col, Row } from "antd";

import { Round, RoundState } from "models";
import { useModal } from "hooks";
import { useRoundMutations } from "hooks/mutations";
import {
  NumericText,
  Button,
  Modal,
  DepositModal,
  LoadingModal,
  RoundAvatar,
} from "components";

import { abbreviateNumber } from "helpers/formatting";
import { formatToDate, formatUTC } from "helpers/date";
import { openNoticeNotification } from "components/Notification";

import { DetailsText } from "./components";

import styles from "./RoundCard.module.scss";

type RoundCardProps = {
  round: Round;
};

type ModalType = "" | "deposit" | "withdraw" | "claim" | "withdrawAndStake";

const RoundCard = ({ round }: RoundCardProps) => {
  const {
    total,
    endDate,
    target,
    reward,
    deposit,
    allowedToWithdraw,
    allowedToClaim,
    withdrawn,
    state,
    depositToken,
    depositTokenAddress,
    rewardToken,
    contractAddress,
    withdrawTime,
    claimTime,
    isReady,
  } = round;

  const { isOpen, onOpen, onClose } = useModal();
  const { withdraw, claim, withdrawAndStake, transactionLink } =
    useRoundMutations(depositTokenAddress, contractAddress);
  const [modalType, setModalType] = useState<ModalType>("");

  const isDepositDisabled = !isReady || state !== RoundState.Active;
  const isClaimDisabled = !isReady || state !== RoundState.Successful;

  const isWithdrawDisabled =
    !isReady || state === RoundState.New || state === RoundState.Active;

  const handleModalOpen = (type: ModalType) => () => {
    setModalType(type);
    onOpen();
  };

  const handleClaim = async () => {
    if (claimTime <= Date.now()) {
      if (Number(allowedToClaim) <= 0) {
        openNoticeNotification(`Nothing to claim`);
      } else {
        try {
          setModalType("claim");
          onOpen();
          await claim();
        } catch (error) {
          console.log(error);
        } finally {
          onClose();
        }
      }
    } else {
      openNoticeNotification(
        `Claiming will be allowed ${formatToDate(claimTime)}`
      );
    }
  };

  const handleWithdraw = async () => {
    if (withdrawTime <= Date.now()) {
      if (Number(allowedToWithdraw) <= 0) {
        openNoticeNotification(`Nothing to withdraw`);
      } else {
        try {
          setModalType("withdraw");

          onOpen();
          await withdraw();
        } catch (error) {
          console.log(error);
        } finally {
          onClose();
        }
      }
    } else {
      openNoticeNotification(
        `Withdrawing will be allowed ${formatToDate(withdrawTime)}`
      );
    }
  };

  const handleWithdrawAndStake = async () => {
    if (withdrawTime <= Date.now()) {
      if (Number(allowedToWithdraw) <= 0) {
        openNoticeNotification(`Nothing to withdraw`);
      } else {
        try {
          setModalType("withdrawAndStake");

          onOpen();
          await withdrawAndStake();
        } catch (error) {
          console.log(error);
        } finally {
          onClose();
        }
      }
    } else {
      openNoticeNotification(
        `Withdrawing will be allowed ${formatToDate(withdrawTime)}`
      );
    }
  };

  const renderModalByType = () => {
    switch (modalType) {
      case "deposit":
        return <DepositModal round={round} />;
      case "withdraw":
      case "claim":
      case "withdrawAndStake":
        return (
          <LoadingModal onClose={onClose} transactionLink={transactionLink} />
        );
      default:
        return null;
    }
  };

  const claimButtonText = `Claim${
    round.state === RoundState.Successful && allowedToClaim > 0
      ? " Available"
      : ""
  }`;

  const claimButtonAdditionalText = `${
    round.state === RoundState.Successful && allowedToClaim > 0
      ? `${abbreviateNumber(allowedToClaim)} `
      : ""
  }${rewardToken.symbol}`;

  return (
    <>
      <Card className={styles.card}>
        <Row className={styles.row} justify="space-between" gutter={[0, 32]}>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 8, offset: 0 }}>
            <Row justify="start">
              <Col>
                <RoundAvatar
                  depositIcon={depositToken.icon}
                  rewardIcon={rewardToken.icon}
                  size={42}
                />
              </Col>

              <Col offset={1}>
                <h5 className={styles.title}>
                  Buy {rewardToken.symbol} for {depositToken.symbol}
                </h5>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 0 }}>
            <Row justify="space-between" gutter={[32, 0]}>
              <Col
                xs={{ span: 8, offset: 8 }}
                md={{ span: 8, offset: 8 }}
                className="gutter-row"
              >
                <DetailsText label="Total invested">
                  <NumericText value={total} prefix="$ " />
                </DetailsText>
              </Col>
              <Col
                xs={{ span: 8, offset: 0 }}
                md={{ span: 8, offset: 0 }}
                className="gutter-row"
              >
                <DetailsText label="Target">
                  <NumericText value={target} prefix="$ " />
                </DetailsText>
              </Col>
            </Row>
            <Row>
              <></>
            </Row>
          </Col>
        </Row>

        <div className={styles.line} />

        <Row className={styles.row} justify="space-between" gutter={[0, 32]}>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 8, offset: 0 }}>
            <Row justify="start">
              <Col>
                <DetailsText label="End date">
                  {formatUTC(+new Date(endDate))}
                </DetailsText>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 16, offset: 0 }}>
            <Row justify="end" gutter={32}>
              <Col span={8} className="gutter-row">
                <DetailsText label="You invested">
                  <NumericText
                    value={deposit}
                    prefix={`${depositToken.symbol} `}
                  />
                </DetailsText>
              </Col>
              <Col span={8} className="gutter-row">
                <DetailsText label="You withdrawn">
                  <NumericText
                    value={withdrawn}
                    prefix={`${depositToken.symbol} `}
                  />
                </DetailsText>
              </Col>
              <Col span={8} className="gutter-row">
                <DetailsText label="Earnings forecast" hint>
                  <NumericText
                    value={reward}
                    prefix={`${rewardToken.symbol} `}
                  />
                </DetailsText>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className={styles.line} />

        <Row className={styles.row} justify="center" gutter={[8, 8]}>
          <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 4 }}>
            <Button
              size="large"
              additionalText={depositToken.symbol}
              disabled={isDepositDisabled}
              onClick={handleModalOpen("deposit")}
              block
            >
              Deposit
            </Button>
          </Col>
          <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 6 }}>
            <Button
              size="large"
              additionalText={claimButtonAdditionalText}
              disabled={isClaimDisabled}
              onClick={handleClaim}
              block
            >
              {claimButtonText}
            </Button>
          </Col>
          <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 5 }}>
            <Button
              size="large"
              additionalText={depositToken.symbol}
              disabled={isWithdrawDisabled}
              onClick={handleWithdraw}
              block
            >
              Withdraw
            </Button>
          </Col>
          <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 9 }}>
            <Button
              size="large"
              additionalText={depositToken.symbol}
              disabled={isWithdrawDisabled}
              onClick={handleWithdrawAndStake}
              block
            >
              Withdraw and Stake
            </Button>
          </Col>
        </Row>
      </Card>

      <Modal visible={isOpen} onCancel={onClose} centered>
        {renderModalByType()}
      </Modal>
    </>
  );
};

export default RoundCard;
