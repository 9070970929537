import { useState } from "react";

import { Row, Col } from "antd";

import { Round } from "models";
import { useRoundMutations } from "hooks/mutations";
import { useModal } from "hooks";
import {
  Button,
  NumericText,
  Modal,
  LoadingModal,
  NumericTextField,
} from "components";

import styles from "./DepositModal.module.scss";

type DepositModalProps = {
  round: Round;
};

const DepositModal = ({
  round: {
    balance,
    contractAddress,
    depositToken,
    depositTokenAddress,
    allowance,
  },
}: DepositModalProps) => {
  const { approve, deposit, transactionLink } = useRoundMutations(
    depositTokenAddress,
    contractAddress
  );
  const { isOpen, onOpen, onClose } = useModal();
  const [inputValue, setInputValue] = useState<number | string>("");

  const maxValue = Number(balance);
  const isDisabledButton =
    !allowance || !inputValue || inputValue > Number(maxValue);

  const resetInputValue = () => setInputValue("");

  const handleApprove = async () => {
    try {
      onOpen();
      await approve();
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  };

  const handleDeposit = async () => {
    try {
      onOpen();
      await deposit(inputValue);
    } catch (error) {
      console.log(error);
    } finally {
      resetInputValue();
      onClose();
    }
  };

  return (
    <div className={styles.depositWrapper}>
      <h4 className={styles.title}>Deposit</h4>

      <Row gutter={[0, 10]}>
        <Col span={24} className={styles.amountWrapper}>
          <span>Balance</span>
          <span className={styles.balance}>
            <NumericText value={balance} precision={3} />{" "}
            {`${depositToken.symbol}`}
          </span>
        </Col>

        <Col span={24}>
          <NumericTextField
            value={inputValue}
            onChange={setInputValue}
            maxValue={maxValue}
            depositToken={depositToken}
          />
        </Col>

        <Col span={24}>{}</Col>
      </Row>

      <Row gutter={[0, { md: 10, sm: 20, xs: 20 }]}>
        <Col span={24}>
          <Button
            className={styles.button}
            onClick={handleApprove}
            disabled={allowance}
          >
            Give permission to use {depositToken.symbol}
          </Button>
        </Col>
        <Col span={24}>
          <Button
            className={styles.button}
            onClick={handleDeposit}
            disabled={isDisabledButton}
          >
            Deposit
          </Button>
        </Col>
      </Row>

      <Modal visible={isOpen} onCancel={onClose} centered>
        <LoadingModal onClose={onClose} transactionLink={transactionLink} />
      </Modal>
    </div>
  );
};

export default DepositModal;
