import { Dictionary } from "lodash";

import { Network } from "models";

import ProductionScanner from "helpers/ProductionScanner";

import ethereumLogo from "assets/ethereum-eth-logo.svg";
import binanceLogo from "assets/binance-coin-bnb-logo.svg";
import ethereumContainedLogo from "assets/ethereum.svg";
import binanceContainedLogo from "assets/bsc.svg";

const IS_PRODUCTION = ProductionScanner.check();

const AMOUNT_OF_BLOCKS_PER_YEAR_ETHEREUM: number = process.env
  .REACT_APP_AMOUNT_OF_BLOCKS_PER_YEAR_ETHEREUM
  ? Number(process.env.REACT_APP_AMOUNT_OF_BLOCKS_PER_YEAR_ETHEREUM)
  : 2254114;

const AMOUNT_OF_BLOCKS_PER_YEAR_BSC: number = process.env
  .REACT_APP_AMOUNT_OF_BLOCKS_PER_YEAR_BSC
  ? Number(process.env.REACT_APP_AMOUNT_OF_BLOCKS_PER_YEAR_BSC)
  : 10500000;

export const ETHEREUM_MAINNET_NETWORK: Network = {
  name: "Ethereum",
  networkId: 1,
  avatar: ethereumLogo,
  avatarContained: ethereumContainedLogo,
  amountOfBlocksPerYear: AMOUNT_OF_BLOCKS_PER_YEAR_ETHEREUM,
  symbol: "ETH",
};

export const BSC_MAINNET_NETWORK: Network = {
  name: "BSC Mainnet",
  networkId: 56,
  avatar: binanceLogo,
  avatarContained: binanceContainedLogo,
  amountOfBlocksPerYear: AMOUNT_OF_BLOCKS_PER_YEAR_BSC,
  symbol: "BNB",
};

export const RINKEBY_NETWORK: Network = {
  name: "Rinkeby",
  networkId: 4,
  avatar: ethereumLogo,
  avatarContained: ethereumContainedLogo,
  amountOfBlocksPerYear: AMOUNT_OF_BLOCKS_PER_YEAR_ETHEREUM,
  symbol: "ETH",
};

export const BSC_TEST_NETWORK: Network = {
  name: "BSC Test",
  networkId: 97,
  avatar: binanceLogo,
  avatarContained: binanceContainedLogo,
  amountOfBlocksPerYear: AMOUNT_OF_BLOCKS_PER_YEAR_BSC,
  symbol: "BNB",
};

const NETWORKS: Dictionary<Network> = {
  // [ETHEREUM_MAINNET_NETWORK.networkId]: ETHEREUM_MAINNET_NETWORK,
  [BSC_MAINNET_NETWORK.networkId]: BSC_MAINNET_NETWORK,
};

if (!IS_PRODUCTION) {
  NETWORKS[BSC_TEST_NETWORK.networkId] = BSC_TEST_NETWORK;
  NETWORKS[RINKEBY_NETWORK.networkId] = RINKEBY_NETWORK;
}

export { NETWORKS };

export const DEFAULT_NETWORK = BSC_MAINNET_NETWORK;
